import React from 'react';

import cn from '../../../lib/classNames';

import styles from './TabButton.module.scss';

export default function TabButton(props) {
	const { isActive = false, onClick, image } = props;
	return (
		<button
			type='button'
			className={cn(styles.button, { [styles['active-tab']]: isActive })}
			onClick={onClick}
		>
			<div className={styles.image}>{image}</div>
		</button>
	);
}
