import React from 'react';
import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';

import cn from '../../../lib/classNames';
import html2canvas from '../../../lib/html2canvas';
import { ReactComponent as DistributionImage } from './images/distribution.svg';
import { ReactComponent as MapImage } from './images/map.svg';
import StatisticsDistributionViCRI from './StatisticsDistributionViCRI';
import StatisticsMapViCRI from './StatisticsMapViCRI';
import TabButton from './TabButton';
import DataLayer from '../../Report/components/DataLayer';
import Download from '../../Report/components/Download';

import styles from './NationalViCRIBlock.module.scss';

export default function NationalViCRIBlock(props) {
	const {
		showDownloadScreenIcon = true,
		dataLayer = -1,
		countries,
		crimeIndexGeography,
		home,
		destination,
		destinationCoordinates,
		distributions,
		isLimitedVersion = false,
		homeCode,
		destinationCode,
	} = props;

	const [activeTab, setActiveTab] = useState('distribution');

	const elementToShare = useRef(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'world-statistics.png');
		});
	};

	const headerTextOptions = {
		map: (
			<>
				National Violent Crime Risk Index
				<p>World map</p>
			</>
		),
		distribution: (
			<>
				National Violent Crime Risk Index
				<p>World distribution</p>
			</>
		),
	};

	const headerText = headerTextOptions[activeTab];

	const existDistributionData =
		distributions &&
		distributions.crimeHRBarChart &&
		distributions.crimeHRBarChart.distribution.length;

	const showTabButtons = !isLimitedVersion && existDistributionData;

	return (
		<div
			className={cn(styles['statistics-container-vicri'], 'section-wrap-white')}
			ref={elementToShare}
		>
			{dataLayer >= 0 && (
				<div className='data-layer-container'>
					<DataLayer numData={dataLayer} />
				</div>
			)}
			<div className={styles.header}>
				<h4>{headerText}</h4>
				<div className={styles['block-right']}>
					{showDownloadScreenIcon && (
						<div className='download-icon' onClick={handleClickShare}>
							<Download className='icons-animation' />
						</div>
					)}

					{showTabButtons && (
						<div className={styles.buttons}>
							<TabButton
								image={<DistributionImage />}
								onClick={() => setActiveTab('distribution')}
								isActive={activeTab === 'distribution'}
							/>
							<TabButton
								image={<MapImage />}
								onClick={() => setActiveTab('map')}
								isActive={activeTab === 'map'}
							/>
						</div>
					)}
				</div>
			</div>

			{activeTab === 'map' && (
				<StatisticsMapViCRI
					countries={countries}
					crimeIndexGeography={crimeIndexGeography}
					home={home}
					destination={destination}
					destinationCoordinates={destinationCoordinates}
				/>
			)}
			{activeTab === 'distribution' && (
				<StatisticsDistributionViCRI
					distributions={distributions}
					homeCode={homeCode}
					destinationCode={destinationCode}
				/>
			)}
		</div>
	);
}
